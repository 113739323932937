import * as React from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CBtnList,
  LPlan,
} from '../../components/_index';
import { setLang } from '../../utils/set-lang';
import '../../assets/_sass/page/recruit.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const lang = setLang();

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        data={{
          title: {
            en: 'recruit',
            ja: '採用情報',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/recruit/kv.png',
              },
              imgSp: {
                src: '/assets/images/recruit/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [],
            current: {
              label: '採用情報',
            },
          }}
        />
      </CJumbotron>

      <section className="u_mtExLarge u_mbExLarge">
        <h2 className="c_sectTitle c_headingLv3">
          「新卒・キャリア・アルバイト」の
          <br />
          採用情報を適宜更新しています。
          <br />
          詳しくは下記からご確認ください。
        </h2>

        <LWrap exClass="u_mbLargeMedium">
          <CBtnList
            exClass="u_mbMiddleLarge"
            data={[
              {
                label: '各種採用情報はこちら',
                link: {
                  href: 'https://www.royalparkhotels.co.jp/recruit/',
                  blank: true,
                },
              },
            ]}
          />
          <hr className="u_mbMiddleLarge" />
          <h3 className="c_sectTitle c_headingLv3">
            採用情報やスタッフの働き方を
            <br className="u_sp" />
            紹介するSNSを開設しました。
            <br />
            ぜひフォローしてください！
          </h3>

          <div
            style={{ maxWidth: '536px' }}
            className="p_recruit_sns u_centerring"
          >
            <a
              className="sns_link u_mb25"
              href="https://www.instagram.com/sendairoyalparkhotel_saiyou/"
              target="_blank"
            >
              <img src="/assets/images/recruit/instagram.png" alt="instagram" />
              @sendairoyalparkhotel_saiyou
            </a>
            <a
              className="sns_link"
              href="https://twitter.com/srphsaiyo"
              target="_blank"
            >
              <img src="/assets/images/recruit/twitter.png" alt="twitter" />
              @srphsaiyo
            </a>
          </div>
        </LWrap>
      </section>
      <LPlan />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
